import { Row } from 'react-bootstrap';
import { ReactSelectBootstrap } from 'react-select-bootstrap';

import { ApplicationPrograms } from "@application_programs/ApplicationPrograms";


const ApplicationProgramSelection = (props) => {

    const options = [];
    ApplicationPrograms.list.forEach((program) => {
        options.push({ value: program.id, label: program.name });
    });


    return (
        <>
            <Row className="application-program-selection-text">
                Please select which study program to apply for:
            </Row>
            <ReactSelectBootstrap
                className="react-select"
                options={options}
                value={{ value: props.application_program.id, label: props.application_program.name }}
                onChange={ props.onChangeApplicationProgram }
                styles={{
                    /* make this react-select behave like an original dropdown */
                    clearIndicator: (provided, state) => ({
                        ...provided,
                        fill: "white"
                    }),
                    control: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.menuIsOpen ? "color-mix(in srgb, var(--bs-secondary) 80%, black)" : "var(--bs-secondary)",
                        color: state.menuIsOpen ? "var(--bs-secondary-color)" : "var(--bs-dropdown-link-color)",
                        border: (true ? state.menuIsOpen ? "1px solid color-mix(in srgb, var(--bs-secondary) 90%, black) !important" : "1px solid var(--bs-secondary) !important" : "1px solid var(--bs-form-invalid-border-color) !important")
                    }),
                    dropdownIndicator: (provided, state) => ({
                        ...provided,
                        fill: "white"
                    }),
                    indicatorSeparator: (provided, state) => ({
                        ...provided,
                        backgroundColor: "white"
                    }),
                    input: (provided, state) => ({
                        ...provided,
                        color: "white"
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        backgroundColor: "var(--bs-body-bg)",
                        border: "1px solid var(--bs-border-color-translucent)",
                        borderRadius: "var(--bs-border-radius)",
                        boxShadow: "none",
                        overflow: "hidden",
                        marginTop: "2px",
                        marginBottom: "2px",
                        zIndex: "1000"
                    }),
                    menuList: (provided, state) => ({
                        ...provided,
                        padding: "var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x)"
                    }),
                    noOptionsMessage: (provided, state) => ({
                        ...provided,
                        color: "var(--bs-dropdown-link-color)"
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "var(--bs-secondary-bg) !important" : state.isFocused ? "var(--bs-tertiary-bg)" : "var(--bs-body-bg)",
                        color: "var(--bs-dropdown-link-color)",
                        "&:active": {
                            backgroundColor: "var(--bs-primary)",
                            color: "white"
                        }
                    }),
                    placeholder: (provided, state) => ({
                        ...provided,
                        color: "white"
                    }),
                    singleValue: (provided, state) => ({
                        ...provided,
                        color: "white"
                    }),
                    valueContainer: (provided, state) => ({
                        ...provided,
                        color: "inherit"
                    })
                }}
            />
        </>
    );
    
}

export default ApplicationProgramSelection;