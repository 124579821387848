import { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';

import Store from '@model/Store';
import { validateExtendedAlphaNumericalString, validateNumber } from '@shared/validationFunctions';

import * as Model from '@model/Types'

import { useCallback } from 'react'

const validateSubject = (subject) => {
    return validateExtendedAlphaNumericalString(subject);
}
const validateCourseCode = (courseCode) => {
    return validateExtendedAlphaNumericalString(courseCode) || courseCode.length <= 0;
}
const validateComment = (comment) => {
    return validateExtendedAlphaNumericalString(comment) || comment.length <= 0;
}
const validateCredits = (credits) => {
    return validateNumber(credits) && parseInt(credits) > 0 && parseInt(credits) <= 9999;
}


// component to edit the course information - mainly the modal
const CourseDetailsModal = (props) => {

    // const course = Store(state => state.courses.find((c) => c.id === props.courseId));

    // local state variables for all the course information
    const [degree, setDegree] = useState(props.course.degree);
    const [subject, setSubject] = useState(props.course.subject);
    const [courseCode, setCourseCode] = useState(props.course.course_code);
    const [credits, setCredits] = useState(props.course.credits);
    const [comment, setComment] = useState(props.course.comment);
    const [compulsory, setCompulsory] = useState(props.course.compulsory);

    // helper function evaluate all validation functions
    const isValid = useCallback(() => {

        let valid = true;
        valid &= validateSubject(subject);
        valid &= validateCourseCode(courseCode);
        valid &= validateCredits(credits);
        valid &= validateComment(comment);
        return valid;
        
    }, [comment, courseCode, credits, subject]);

    // handle the save button
    // disabling eslint, we would either way need to hook to all the state variables including props
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSave = () => {

        Store.getState().updateCourse(
            new Model.Course(
                degree,
                subject,
                courseCode,
                parseFloat(credits),
                comment,
                compulsory,
                props.course.id
            )
        );

        props.handleClose();

    };

    useEffect(() => {

        if (props.show) {
            setDegree(props.course.degree);
            setSubject(props.course.subject);
            setCourseCode(props.course.course_code);
            setCredits(props.course.credits);
            setComment(props.course.comment);
            setCompulsory(props.course.compulsory);
        }

    }, [props.course, props.show]);

    const modalTitle = (createNew) => {
        if (createNew) return "Create new";
        else return "Edit";
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (props.show && event.key === 'Enter') {
                if (isValid()) {
                    handleSave();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, isValid]);


    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle(props.createNew)} course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="modal-form">
                        <div>
                            Please fill in the information as on your transcript of records.
                        </div>

                        <Form.Group>
                            <Form.Label className="mandatory">Course name</Form.Label>
                            <Form.Control type="text"
                                required
                                value={subject}
                                placeholder="Course name as on the transcript of records"
                                onChange={(e) => { setSubject(e.target.value) }}
                                isInvalid={!validateSubject(subject)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Course code (optional)</Form.Label>
                            <Form.Control type="text"
                                required
                                value={courseCode}
                                placeholder="Abbreviation or course number as on transcript of records"
                                onChange={(e) => { setCourseCode(e.target.value) }}
                                isInvalid={!validateCourseCode(courseCode)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Comment (optional)</Form.Label>
                            <Form.Control as="textarea"
                                rows={5}
                                required
                                value={comment}
                                placeholder="Would you like to add something?"
                                onChange={(e) => { setComment(e.target.value) }}
                                isInvalid={!validateComment(comment)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="mandatory">Credits</Form.Label>
                            <Form.Control type="number"
                                required
                                value={credits}
                                onChange={(e) => { 
                                    if (validateCredits(e.target.value))
                                        setCredits(e.target.value)
                                }}
                                isInvalid={!validateCredits(credits)}
                            />
                            <Form.Text>
                                Use the credit unit system associated with this degree
                            </Form.Text>
                        </Form.Group>

                        <Form.Group>
                            <Form.Check type="switch"
                                required
                                checked={compulsory}
                                label="Course is compulsory"
                                onChange={() => { setCompulsory(!compulsory) }}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSave} disabled={!isValid()}>Save Changes</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default CourseDetailsModal;