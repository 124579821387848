import { Buffer } from 'buffer';

import { externalToInternalCredits } from '@model/CreditPointCalc';

const attachmentName = 'application_export.json';
const attachmentIsHidden = false;
const cpPrintPrecision = 2;

export const generatePdfTemplate = (store, program, font = 'Roboto') => {
  // reporting helpers
  const getAllocatedCpByProgramCourse = (program_course_id) => {
    return store.getState().program_qualifications
      .filter((qualification) => {
        return qualification.application_program === program.id && qualification.target === program_course_id;
      })
      .map((qualification) => {
        const course = store.getState().courses.find(c => c.id === qualification.course);
        const degree = store.getState().degrees.find(d => d.id === course.degree);

        return externalToInternalCredits(
          degree.program.credits_nominal,
          degree.program.duration_nominal,
          qualification.credits);
      })
      .reduce((acc, val) => { return acc + val; }, 0);
  };

  const getAllocatedCpByProgramField = (program_field) => {
    return program_field.courses
      .map((program_course) => {
        return getAllocatedCpByProgramCourse(program_course.id);
      })
      .reduce((acc, val) => { return acc + val; }, 0);
  };


  // TODO: which auto colums to mark as 'noWrap: true'
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [40, 60, 40, 60],
    styles: {
      header: {
        fontSize: 15,
        bold: true,
      },
      subheader: {
        fontSize: 12,
        bold: true
      },
      subsubheader: {
        fontSize: 11,
        bold: true,
        italics: true,
      },
      quote: {
        italics: true
      },
      small: {
        fontSize: 8
      },
      table: {
        margin: [0, 5, 0, 15]
      },
      tableHeader: {
        bold: true,
        fontSize: 11,
      },
      headerfooter: {
        fontSize: 8,
        color: 'grey',
      },
    },
    defaultStyle: {
      color: 'black',
      font: font,
      fontSize: 10,
    },
    // header
    header: {
      margin: [20, 20, 20, 0],
      columns: [
        { width: '*', style: 'headerfooter', text: `${program.id.toUpperCase()} ${store.getState().personal_information.application_number}` },
        { width: 'auto', style: 'headerfooter', text: (new Date()).toISOString() }
      ]
    },
    // footer
    footer: (currentPage, pageCount) => {
      return {
        margin: [20, 35, 20, 0],
        columns: [
          { width: '*', style: 'headerfooter', text: store.getState().id },
          { width: 'auto', style: 'headerfooter', text: `${currentPage}/${pageCount}` }
        ]
      }
    },

    // document contents
    content: [
      // First Page Header
      { text: 'TUHH Masters Application', style: 'header' },
      { text: program.name, italics: true, margin: [0, 0, 0, 20] },

      // Personal Information
      { text: 'Personal Information', style: 'subheader' },
      {
        style: 'table',
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*'],
          body: [
            [
              { style: 'tableHeader', text: 'Name' },
              { style: 'tableHeader', text: 'Surname' },
              { style: 'tableHeader', text: 'Application No.' },
              { style: 'tableHeader', text: 'Program' },
            ],
            [
              store.getState().personal_information.name,
              store.getState().personal_information.surname,
              store.getState().personal_information.application_number,
              program.name
            ]
          ]
        }
      },

      // comments overall application
      { text: 'Comment', style: 'subheader' },
      { text: store.getState().comment },


      // Degrees with Courses
      { text: 'Degrees', style: 'subheader', pageBreak: 'before' },
      ...store.getState().degrees.map((degree, index) => {
        return [
          { text: `${index} - ${degree.program.subject}, ${degree.program.degree}`, style: 'subsubheader' },
          {
            style: 'table',
            table: {
              headerRows: 0,
              widths: ['auto', '*'],
              body: [
                // granting institution
                ['University', degree.granting_institution.name],
                ['College', degree.granting_institution.college],
                ['Country', `${degree.granting_institution.country.label} [${degree.granting_institution.country.value}]`],

                // program
                ['Degree', degree.program.degree],
                ['Subject', degree.program.subject],
                ['Specialization', degree.program.specialization],
                ['Duration Nominal', degree.program.duration_nominal],
                ['Credits Nominal', degree.program.credits_nominal],
                ['Credits System', degree.program.credit_system],

                // final report
                ['Studies Start', `${degree.report.date_start.month + 1}/${degree.report.date_start.year}`],
                ['Studies End', `${degree.report.date_end.month + 1}/${degree.report.date_end.year}`],
                ['Completed', degree.report.completed ? 'yes' : 'no'],
                ['Credits', `${degree.report.credits} ${degree.program.credit_system}`],
              ]
            }
          },

          // courses
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: ['auto', 'auto', '*', 'auto'],
              body: [
                [
                  { style: 'tableHeader', text: 'Code' },
                  { style: 'tableHeader', text: 'Course' },
                  { style: 'tableHeader', text: 'Comment' },
                  { style: 'tableHeader', text: degree.program.credit_system },
                ],
                ...store.getState().courses
                  .filter(c => c.degree === degree.id)
                  .map((course) => {
                    return [
                      course.course_code || '-',
                      { text: `${course.subject}${course.compulsory ? '*' : ''}`, bold: course.compulsory },
                      { text: course.comment, italics: true },
                      { text: course.credits, alignment: 'right' },
                    ]
                  })
              ]
            },
          }
        ]
      }),

      // matches / allocations
      { text: 'Prerequisites', style: 'subheader', pageBreak: 'before' },
      {
        style: 'table',
        table: {
          headerRows: 1,
          widths: ['*', 'auto', 'auto', 'auto'],
          body: [
            [
              { style: 'tableHeader', text: 'Requirement' },
              { style: 'tableHeader', text: 'ECTS\nRequired' },
              { style: 'tableHeader', text: 'ECTS\nAllocated' },
              { style: 'tableHeader', text: 'CP\nAllocated' },
            ],
            // show matches with CP unit
            ...program.fields.map((program_field) => {

              // calculate required cp for field
              const required_cp_field = program_field.courses
                .map(c => parseInt(c.required_cp))
                .reduce((acc, val) => { return acc + val; }, 0);

              // allocated cp for field
              const allocated_cp_program_field = getAllocatedCpByProgramField(program_field);

              // build table with field courses and allocated students courses
              return [
                // field name, bold, italics
                [
                  { text: program_field.name, bold: true, italics: true },
                  { text: required_cp_field, bold: true, italics: true, alignment: 'right' },
                  { text: allocated_cp_program_field.toFixed(cpPrintPrecision), bold: true, italics: true, alignment: 'right' },
                  { text: '', bold: true, italics: true },
                ],
                ...program_field.courses.map((program_course) => {

                  // calculate allocated cp for required course
                  const allocated_cp_program_course = getAllocatedCpByProgramCourse(program_course.id);

                  // build table of allocated courses
                  return [
                    // prerequisite course name, bold
                    [
                      { text: program_course.subject, bold: true },
                      { text: program_course.required_cp, bold: true, alignment: 'right' },
                      { text: allocated_cp_program_course.toFixed(cpPrintPrecision), bold: true, alignment: 'right' },
                      { text: '', bold: true },
                    ],
                    ...store.getState().program_qualifications
                      .filter((qualification) => {
                        return qualification.application_program === program.id && qualification.target === program_course.id;
                      })
                      .map((qualification) => {
                        const course = store.getState().courses.find(c => c.id === qualification.course);
                        const degree = store.getState().degrees.find(d => d.id === course.degree);
                        const cp = externalToInternalCredits(
                          degree.program.credits_nominal,
                          degree.program.duration_nominal,
                          qualification.credits);

                        // allocated course row
                        return [
                          { text: course.subject, italics: true },
                          { text: '', italics: true },
                          {
                            text: cp.toFixed(cpPrintPrecision),
                            italics: true,
                            alignment: 'right'
                          },
                          {
                            columns: [
                              {
                                width: '*',
                                text: degree.program.credit_system,
                                italics: true,
                              },
                              {
                                width: 'auto',
                                text: qualification.credits,
                                italics: true,
                                margin: [5, 0, 0, 0],
                              }
                            ]
                          },
                        ];
                      })
                  ];
                }).flat()
              ];
            }).flat()
          ]
        }
      },

      // unallocated
      { text: 'Unallocated', style: 'subheader', pageBreak: 'before' },
      {
        style: 'table',
        table: {
          headerRows: 1,
          widths: ['*', 'auto', 'auto'],
          body: [
            [
              { style: 'tableHeader', text: 'Course' },
              { style: 'tableHeader', text: 'ECTS' },
              { style: 'tableHeader', text: 'CP' },
            ],
            ...store.getState().courses
              .filter(course => store.getState().getTotalAllocatedCreditsByCourseId(program.id, course.id) !== course.credits)
              .map((course) => {
                const degree = store.getState().degrees.find(d => d.id === course.degree);
                const unallocated_cp = course.credits - store.getState().getTotalAllocatedCreditsByCourseId(program.id, course.id);
                const cp = externalToInternalCredits(
                  degree.program.credits_nominal,
                  degree.program.duration_nominal,
                  unallocated_cp);
                
                return [
                  { text: course.subject, italics: true },
                  {
                    text: cp.toFixed(cpPrintPrecision),
                    italics: true,
                    alignment: 'right'
                  },
                  {
                    columns: [
                      {
                        width: '*',
                        text: degree.program.credit_system,
                        italics: true,
                      },
                      {
                        width: 'auto',
                        text: unallocated_cp,
                        italics: true,
                        margin: [5, 0, 0, 0],
                      }
                    ]
                  },
                ];
              })
          ]
        }
      },

    ],
  };

  return docDefinition;
};

export const attachStoreJsonToPdf = (store, pdfKitDoc) => {
  /* return */
  pdfKitDoc.file(
    Buffer.from(store.getState().exportJSON()),
    {
      name: attachmentName,
      hidden: attachmentIsHidden,
    }
  );
};
