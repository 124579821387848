import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Tab, Nav } from "react-bootstrap";

import { ApplicationPrograms } from "@application_programs/ApplicationPrograms";
import Header from "@components/Header";
import Student from "@components/Student";
import Degrees from "@components/Degrees";
import Matching from "@components/Matching";
import Export from "@components/Export";
import ApplicationProgramSelection from "@components/ApplicationProgramSelection";

import "./App.css";


const App = (props) => {

    const navigate = useNavigate();

    const { application_program_id } = useParams();
    const [ applicationProgram, setApplicationProgram ] = useState(ApplicationPrograms.getValidProgramById(application_program_id));

    const onChangeApplicationProgram = (e) => {

        setApplicationProgram(ApplicationPrograms.getValidProgramById(e.value));
        navigate("../" + ApplicationPrograms.getValidProgramById(e.value).id);

    }

    return (
        <Container>

            {/* header information */}
            <Header application_program_name={applicationProgram.name} />

            {/* selection of application program information */}
            <ApplicationProgramSelection application_program={applicationProgram} onChangeApplicationProgram={onChangeApplicationProgram} />
            
            {/* student information */}
            <Student />

            {/* tabs for credits, courses, and export including generating the pdf */}
            <Row>

                <Tab.Container defaultActiveKey="prerequisites">
                    
                    {/* navigation bar */}
                    <Nav as="ul" variant="pills" className="nav-fill nav-justified justify-content-center flex-column flex-sm-row">
                        <Nav.Item as="li">
                            <Nav.Link as="button" eventKey="prerequisites" className="prerequisites">Our Prerequisites</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as="button" eventKey="credits" className="credits">Your Credits</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as="button" eventKey="export" className="export">Export</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    
                    {/* the tabs */}
                    <Tab.Content className="process-tabs">
                        <Tab.Pane eventKey="prerequisites">

                            {/* matching the prerequisites and the student courses */}
                            <Matching application_program={applicationProgram} />

                        </Tab.Pane>
                        <Tab.Pane eventKey="credits">

                            {/* component to add the degrees and courses */}
                            <Degrees />

                        </Tab.Pane>
                        <Tab.Pane eventKey="export">

                            <Export application_program={applicationProgram} />

                        </Tab.Pane>
                    </Tab.Content>

                </Tab.Container>

            </Row>
        </Container>
    );

}

export default App;
