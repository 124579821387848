import { Button, Card, Col, Form, InputGroup, ProgressBar, Row } from 'react-bootstrap';
import { BsFillTrash3Fill } from 'react-icons/bs';

import Store from '@model/Store';
import { useShallow } from 'zustand/react/shallow'


// component representing a course of the student as a card in the matching mode (matching courses to a prerequisite)
const MatchAllocationCard = (props) => {

    // application_program
    // match
    // index

    const handleDelete = () => {
        Store.getState().removeMatching(props.match.id);
    }
    
    // const qualification = Store(state => state.program_qualifications.find((pq) => pq.id === props.match.id));
    const qualification = props.match;
    const course = Store(useShallow(state => state.getCourseById(qualification.course)));
    const degree = Store(useShallow(state => state.getDegreeById(qualification.degree)));
    const totalCredits = Store(useShallow(state => state.getTotalAllocatedCreditsByCourseId(props.application_program.id, course.id)));
    
    // add a credit to the matching as long as the total credits of the course are not exceeded
    const handleCreditsPlus = () => {
        Store.getState().incrementMatchingCredits(qualification.id);
    }
    // remove a credit from the matching as long as the matching credits are not 0
    const handleCreditsMinus = () => {
        Store.getState().decrementMatchingCredits(qualification.id);
    }
    const handleCreditChange = (value) => {
        Store.getState().setMatchingCredits(qualification.id, value);
    }

    const getProgressbarLabel = () => {
        return String(totalCredits) + " / " + String(course.credits);
    }


    return (
        <>

            <Col className="student-match-col" xs="12" sm="12" md="12" lg="6" xl="6" xxl="6">
                <Card className="student-match">
                    <Card.Header>
                        <Row>
                            <Col className="course-title">
                                {course.subject}
                            </Col>
                            <Col className="col-auto">
                                <Button as="div" variant="outline-secondary" size="sm" className="ms-2" onClick={handleDelete}><BsFillTrash3Fill /></Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text className="course-code">{course.course_code}</Card.Text>
                        <Card.Text className="course-degree">{degree.granting_institution.name}</Card.Text>
                        <Card.Text className="course-comment">{course.comment}</Card.Text>
                        <Row>
                            <Col>
                                <Card.Text>Credit Points ({degree.program.credit_system}): {course.credits}</Card.Text>
                            </Col>
                            <Col>
                                <Card.Text>Compulsory: {course.compulsory ? "Yes" : "No"}</Card.Text>
                            </Col>
                        </Row>
                        <Card.Text className="credits-description">Allocated credit points for this course:</Card.Text>
                        <Form>
                            <Form.Group>
                                <InputGroup className="mb-3">
                                    {/* eslint-disable-next-line */}
                                    <Button disabled={qualification.credits == 0} className="plus-minus" variant="outline-secondary" onClick={handleCreditsMinus}>-</Button>
                                        <Form.Control 
                                            aria-label="Credits" 
                                            value={ qualification.credits }
                                            onChange={(e) => handleCreditChange(e.target.value)}
                                            className="credits"
                                        />
                                    {/* eslint-disable-next-line */}
                                    <Button disabled={(qualification.credits == course.credits || totalCredits == course.credits)} className="plus-minus" variant="outline-secondary" onClick={handleCreditsPlus}>+</Button>
                                </InputGroup>
                            </Form.Group>
                        </Form>
                        <Card.Text className="credits-description">Overall allocated credit points:</Card.Text>
                        <ProgressBar now={(totalCredits / course.credits) * 100} label={ getProgressbarLabel() } />
                    </Card.Body>
                </Card>
            </Col>

        </>
    );

}

export default MatchAllocationCard;